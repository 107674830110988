@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.App {
	text-align: center;
	font-size: 18px;
}

.App-header h1 .big { 
	color: #ba3434; 
} 

.big { 
	font-size: 2em;
	vertical-align: middle;
} 

.App form {
	text-align: left; 
} 

button { 
	margin-top: 1em;
	font-weight: 900; 	
}

.btn { 
	border: none; 
} 

.modal { 
	display: block;
} 

.col button {
	text-align: center; 
} 

.btn-close {
	display: none;
} 

.player-card:first-child{ 
	text-align: right; 
} 

.player-card { 
	text-align: left; 
}

.playing { 
	font-weight: 800; 
} 

.playing .player-color { 
	 height: 3em; 
	 width: 3em; 
} 

.player-color { 
	height: 2.5em;
	width: 2.5em;
	margin: 1em;
} 

.score {
	font-weight: 900; 
}

.gameboard .board-space { 
	margin: 1em auto;
	height: 1.5em;
	width: 1.5em;
	background-color: white;
} 

.player-color,
.gameboard .board-space { 
	text-align: center; 
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	transition: all .5s ease-in-out;
	transform-origin: left top;
}

.yellow { 
	color: black !important; 
	background-color: #ffe002 !important; 	
} 
.red { 
	background-color: #ba3434 !important; 
} 
.blue { 
	background-color: #0072bb !important; 
}
.hide { 
	display: none;
}

.gameboard .row:first-child .col {
	background-color: white;
	padding-bottom: 1em;
} 

.gameboard .col { 
	background-color: #3473ba;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
